<template>
  <div class="notice_list_wrap">
    <div class="notice_list" v-for="(data, index) in boardData" :key="`notice${index}`"
         @click="moveDetail(`${data.wr_id}`)">
      <button>
        <div class="notice_title">{{tableIdx(index)}}</div>
        <div class="notice_content">{{ data.wr_subject }}</div>
        <div class="notice_date">{{ data.created_at }}</div>
      </button>
    </div>
  </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";

export default {
  name: "BoardListNoticeLayout",
  mixins: [alertMixins],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
    boardData: {default: () => {}},
      paginationData: {default: {page: 1, size: 10, total: 0}},
  },
  data() {
    return{
      idx: 1,
    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    moveDetail(data){
      this.$router.push(`/notice/${data}`)
    },
    tableIdx(index) {
      return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
    },
  },
  watch: {

  },
}
</script>

<style scoped>

</style>
